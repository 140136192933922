html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root, .portrait-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.portrait {
  border-radius: 65%;
  max-width: 50vw;
  max-height: 50vh;
}

.icon {
  max-width: 100%;
  max-height: 100%;
}

.footer {
  min-height: 2rem;
  width: 100%;
  text-align: center;
}

.links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.link {
  height: 12.5vw;
  width: 12.5vw;
  max-width: 80px;
  max-height: 80px;
}
.gitlab-container {
  padding: 9px;
}
/* h1 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
} */

h1, h3 {
  text-align: center;
}
